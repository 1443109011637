import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {TokenStorage} from '../security/token.storage';

@Injectable({
  providedIn: 'root'
})
export class IdleTimeoutService {
  private timeoutId: any;
  private readonly timeoutDuration = 15 * 60 * 1000; // 15 минут

  constructor(private router: Router,
              private tokenStorage: TokenStorage) {}

  startMonitoring(): void {
    this.resetTimeout();

    // Отслеживаем события активности
    ['mousemove', 'keydown', 'click', 'touchstart'].forEach(event => {
      window.addEventListener(event, () => this.resetTimeout());
    });
  }

  private resetTimeout(): void {
    // Сбрасываем предыдущий таймер
    if (this.timeoutId) {
      clearTimeout(this.timeoutId);
    }

    // Устанавливаем новый таймер
    this.timeoutId = setTimeout(() => {
      this.logoutUser();
    }, this.timeoutDuration);
  }

  private logoutUser(): void {
    // Очистка токена и редирект на страницу логина
    this.tokenStorage.clear();
    this.router.navigate(['login']);
  }
}
