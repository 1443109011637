import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormArray, FormGroup, FormBuilder, Validators} from "@angular/forms";
import {IpRange} from "../../model/ip-range";
import {AbonentService} from "../../service/abonent.service";
import {ActivatedRoute} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {Abonent} from "../../model/abonent";
import {Location} from "@angular/common";
import {Region} from "../../model/region";
import {RegionService} from "../../service/region.service";
import {SubjectSelectComponent} from "../../subject/subject-select/subject-select.component";
import {MatDialog} from "@angular/material";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {IpAddress} from '../../model/ip-address';
import {HttpParams} from '@angular/common/http';
import {Subject} from '../../model/subject';

@Component({
  selector: 'app-abonent-edit',
  templateUrl: './abonent-edit.component.html',
  styleUrls: ['./abonent-edit.component.css']
})
export class AbonentEditComponent implements OnInit, OnDestroy {
  id: number = 0;
  header: string;
  isLoading = false;
  form: FormGroup;
  regionList: Region[] = [];
  private translationSubscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private abonentService: AbonentService,
    private regionService: RegionService,
    private location: Location,
    private route: ActivatedRoute,
    private notifierService: NotifierService,
    private translateService: TranslateService) { }

  ngOnInit(isReload?: boolean) {
    this.id = +this.route.snapshot.paramMap.get('id');
    if (!isReload) {
      this.handleInitialLoad();
    } else {
      this.handleReload();
    }
    this.buildForm(new Abonent());
  }

  private handleInitialLoad() {
    this.regionService.getMainAll().subscribe(data => {
      this.setRegionsTranslation(data);
      this.regionList = data;
      if (this.id !== 0) {
        this.setHeaderTranslation('OTHER.CHANGE_ABONENT');
        this.loadAbonent(this.id);
      } else {
        this.setHeaderTranslation('OTHER.ADD_ABONENT');
      }
    });
  }

  private handleReload() {
    this.loadAbonent(this.id);
  }

  private setHeaderTranslation(translationKey: string) {
    const subscription = this.translateService.stream(translationKey).subscribe(translate => {
      this.header = translate;
    });
    this.translationSubscriptions.push(subscription);
  }

  setRegionsTranslation(regions: Region[]) {
    this.translateService.stream('REGIONS').subscribe(translations => {
      regions.forEach((item, index) => {
        item.name = translations[index];
      });
    });
  }

  private buildForm(editableAbonent: Abonent) {
    this.form = this.formBuilder.group({
      id: [editableAbonent.id],
      subject: [editableAbonent.subject, Validators.required],
      region: [this.findRegion(editableAbonent.region == null ? 0 : editableAbonent.region.id)],
      ipRangeSet: this.createIpRangeSet(editableAbonent.ipRangeSet)
    });
  }

  private createIpRangeItem(ipRange: IpRange): FormGroup {
    return this.formBuilder.group({
      id: [ipRange.id],
      eshdiConnectDate: this.parseDate(ipRange.eshdiConnectDate),
      eshdiDisconnectDate: this.parseDate(ipRange.eshdiDisconnectDate),
      value: [ipRange.value, [
        Validators.required,
        Validators.pattern('^(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(?:\/(?:[0-9]|[1-2][0-9]|3[0-2]))?$')
      ]],
      ipv6: [ipRange.ipv6],
      eshdiConnectedOperator: [ipRange.eshdiConnectedOperator],
      ipAddresses: [ipRange.ipAddresses || []]
    });
  }

  private createIpRangeSet(ipRangeSet: IpRange[]): FormArray {
    let list = this.formBuilder.array([]);
    if (ipRangeSet != null && ipRangeSet.length > 0) {
      ipRangeSet.forEach(ipRange => {
        list.push(this.createIpRangeItem(ipRange));
      });
    } else
      list.push(this.createIpRangeItem(new IpRange));
    return list;
  }

  private findRegion(id: number): Region {
    return this.regionList.find(r => r.id == id);
  }

  private parseDate(dateStr: string): any {
    return dateStr != null ? new Date(dateStr) : new Date();
  }

  loadAbonent(id: number) {
    this.isLoading = true;
    if (this.id == 0) {
      this.buildForm(new Abonent());
    } else {
      this.abonentService.get(id).subscribe(
        data => {
          this.isLoading = false;
          if (data != null) {
            if (data.region != null)
              this.regionList.unshift(data.region);
            this.buildForm(data);
          }
        }
      );
    }
  }

  onSubmit() {
    this.f['subject'].markAsTouched();

    if (this.form.invalid)
      return;

    this.isLoading = true;
    this.abonentService.edit(this.form.value).subscribe(
      () => {
        this.isLoading = false;
        let message: string = this.translateService.instant('BASIC.DATA_SAVED');
        this.notifierService.notify('success', message);
        this.location.back();
      }, error => {
        this.isLoading = false;
      }
    );
  }

  addIpRange() {
    this.ipRangeSet.push(this.createIpRangeItem(new IpRange()));
  }

  deleteIpRange(index: number) {
    if (this.ipRangeSet.length <= 1)
      return;
    if (this.ipRangeSet.at(index).get('id').value != null)
      this.ipRangeSet.at(index).disable();
    else
      this.ipRangeSet.removeAt(index);
  }

  recoverIpRange(index: number) {
    this.ipRangeSet.at(index).enable();
  }

  get f() {
    return this.form.controls;
  }

  get ipRangeSet() {
    return this.form.get('ipRangeSet') as FormArray;
  }

  getCurrentSubject() {
    let sbj = this.f['subject'].value;
    if (sbj != null && sbj.id != null)
      return sbj.bin + ' / ' + sbj.name;
    else
      return '';
  }

  selectSubject() {
    const dialogRef = this.dialog.open(SubjectSelectComponent);

    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        this.f['subject'].setValue(data);
      }
    });
  }

  getIps(index: number) {
    const ipRangeControl = this.ipRangeSet.at(index);
    const cidr = ipRangeControl.get('value').value;

    if (!cidr) {
      this.notifierService.notify('error', 'CIDR is required to fetch IP addresses');
      return;
    }

    let params = new HttpParams().set('cidr', cidr);

    this.abonentService.getIps({ params: params }).subscribe(data => {
      // Привязываем IP-адреса к текущему ipRange
      const ipRange = ipRangeControl.value as IpRange;
      ipRange.ipAddresses = data;
      ipRange.ipAddresses.forEach(ip =>{
        if (this.f['subject'].value != null){
          ip.subjects.push(this.f['subject'].value);
        }
      });
      ipRangeControl.patchValue({ ipAddresses: ipRange.ipAddresses }); // Обновляем форму
    }, error => {
      this.notifierService.notify('error', 'Failed to fetch IP addresses');
    });
  }

  removeSubject(ip: IpAddress, subject: Subject) {
    ip.subjects = ip.subjects.filter(s => s.id !== subject.id);
  }


  selectMultSubjects(ip: IpAddress) {
    const dialogRef = this.dialog.open(SubjectSelectComponent);

    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        // Проверяем, добавлен ли уже такой субъект
        const isAlreadyAdded = ip.subjects.some(subject => subject.id === data.id);
        if (!isAlreadyAdded) {
          ip.subjects.push(data);
        } else {
          //  уведомить пользователя, что субъект уже добавлен
          this.notifierService.notify('error', 'Этот субъект уже добавлен.');
        }
      }
    });
  }

  ngOnDestroy() {
    this.translationSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
