import { Component, OnInit } from '@angular/core';
import {AbonentService} from "../../service/abonent.service";
import {NotifierService} from "angular-notifier";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-abonent-import',
  templateUrl: './abonent-import.component.html',
  styleUrls: ['./abonent-import.component.css']
})
export class AbonentImportComponent implements OnInit {
  files: any[];
  report: string;
  isLoading = false;

  constructor(
    private abonentService: AbonentService,
    private notifierService: NotifierService,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
  }

  onChange(event: any) {
    this.files = event.srcElement.files as any[];
  }

  importFile() {
    this.isLoading = true;
    let file: File = this.files[0];
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    this.abonentService.importFile(formData).subscribe(report => {
      let message: string = this.translateService.instant('BASIC.DATA_SAVED');
      this.notifierService.notify('success', message);
      this.report = report['content'];
      this.files = null;
      this.isLoading = false;
    });
  }
}
