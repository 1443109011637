import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {Subject} from "../model/subject";
import {HttpService} from "./http.service";
import {SubjectType} from "../model/subject-type";
import {ActivityType} from "../model/activity-type";

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  constructor(private http: HttpService) { }

  public getAll(options?: Object): Observable<Subject[]> {
    return this.http.get('/subject/all', options);
  }

  public getCount(options?: Object): Observable<number> {
    return this.http.get('/subject/all/count', options);
  }

  public get(id: number): Observable<Subject> {
    return this.http.get('/subject/' + id);
  }

  public getByType(type: SubjectType): Observable<Subject[]> {
    return this.http.get('/subject/type/' + type.id);
  }

  public edit(subject: Subject): Observable<any> {
    return this.http.post('/subject/operator', subject);
  }

  public deleteSubject(id: number): Observable<any> {
    return this.http.post('/subject/delete', id);
  }

  public getAllTypes(): Observable<SubjectType[]> {
    return this.http.get('/subject/type/all');
  }

  public getParentTypes(): Observable<SubjectType[]> {
    return this.http.get('/subject/type/parent');
  }

  public getChildTypes(): Observable<SubjectType[]> {
    return this.http.get('/subject/type/child');
  }

  public getActivityTypes(): Observable<ActivityType[]> {
    return this.http.get('/subject/activity-types')
  }
}
