import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: ['./language-select.component.css']
})
export class LanguageSelectComponent implements OnInit {

  constructor(private translateService: TranslateService) {
  }

  ngOnInit() {
  }

  setLanguage(lang: string) {
    this.translateService.use(lang);
    localStorage.setItem('selectedLanguage', lang);
  }

}
