import {Component, OnInit, ViewChild} from '@angular/core';
import {MatSort, MatTableDataSource, MatDialog, MatPaginator} from "@angular/material";
import {Abonent} from "../../model/abonent";
import {Router, ActivatedRoute} from "@angular/router";
import {NotifierService} from "angular-notifier";
import {AbonentService} from "../../service/abonent.service";
import {DeleteDialogComponent} from "../../delete-dialog/delete-dialog.component";
import {IpRange} from "../../model/ip-range";
import {HttpParams} from "@angular/common/http";
import {animate, transition, style, state, trigger} from "@angular/animations";
import {ActivityType} from "../../model/activity-type";
import {SubjectType} from "../../model/subject-type";
import {SubjectService} from "../../service/subject.service";
import {FormGroup, FormBuilder} from "@angular/forms";
import {Subject} from "../../model/subject";
import {SubjectSelectComponent} from "../../subject/subject-select/subject-select.component";
import {TranslateService} from "@ngx-translate/core";
import {IpRangeService} from "../../service/ip-range.service";
import {NgxPermissionsService} from "ngx-permissions";

let emptyType = new SubjectType();
emptyType.id = 0;
emptyType.name = 'Все';
let emptyParent = new Subject();
let empty = new IpRange();
emptyParent.id = 0;
emptyParent.bin = '0';
emptyParent.name = 'Все';
let emptyActivityType = new ActivityType();
emptyActivityType.id = 0;
emptyActivityType.name = 'Все';


@Component({
  selector: 'app-abonent-list',
  templateUrl: './abonent-list.component.html',
  styleUrls: ['./abonent-list.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class AbonentListComponent implements OnInit {
  displayedColumns: string[] = ['id', 'created', 'updated', 'createdBy', 'subjectBin', 'subjectName', 'organizationSubject', 'region','operatorAsn'];
  isLoading = false;
  expandedElement: Abonent | null;
  pageSizeArray = [10, 25, 50, 100];
  isCheckbox = false;

  filter: FormGroup;
  types: SubjectType[] = [emptyType];
  parents: Subject[] = [emptyParent];
  activityTypes: ActivityType[] = [emptyActivityType];
  changedIpRange: IpRange[] = [empty];

  dataSource: MatTableDataSource<Abonent>;

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private abonentService: AbonentService,
    private ipRangeService: IpRangeService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private subjectService: SubjectService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private notifierService: NotifierService,
    private translateService: TranslateService) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
  }

  async ngOnInit() {
    try {
      this.checkRole();
      this.buildFilter();
      this.f['parent'].disable();

      let subjectTypesData = await this.subjectService.getAllTypes().toPromise();
      let activityTypesData = await this.subjectService.getActivityTypes().toPromise();

      this.setNamesTranslation(subjectTypesData, 'COMPANIES.TYPES');
      this.setNamesTranslation(activityTypesData, 'COMPANIES.ACTIVITY_FORM');
      this.setNamesTranslation([emptyType, emptyParent, emptyActivityType], 'OTHER.ALL');

      this.types = this.types.concat(subjectTypesData);
      this.activityTypes = this.activityTypes.concat(activityTypesData);

      this.route.queryParams.subscribe(params => {
        let subjectId = +params['subject'];
        // let createdById = +params['createdBy'];
        if (subjectId != null && !isNaN(subjectId) && subjectId != 0)
          this.loadBySubject(subjectId);
          // else if (createdById != null && !isNaN(createdById) && createdById != 0)
        //   this.loadByCreatedById(createdById);
        else
          this.loadData();
      });
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  setNamesTranslation(data: any, key: string) {
    this.translateService.stream(key).subscribe(translations => {
      data.forEach((item, index) => {
        if(Array.isArray(translations)) {
          item.name = translations[index];
        } else {
          item.name = translations;
        }
      });
    });
  }

  add() {
    this.router.navigate(['abonent']);
  }
  edit(abonent: Abonent) {
    this.router.navigate(['abonent/' + abonent.id]);
  }

  remove(abonent: Abonent) {
    //noinspection TypeScriptUnresolvedFunction
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: abonent.id
    });

    dialogRef.afterClosed().subscribe(data => {
      if (data) {
        this.abonentService.remove(abonent.id).subscribe(() => {
          let message: string = this.translateService.instant('BASIC.REMOVED');
          this.notifierService.notify('success', message);
          this.loadData();
        });
      }
    });
  }

  loadData() {
    this.isLoading = true;
    let params = new HttpParams()
      .set('subject', this.f['subject'].value == null ? 0 : this.f['subject'].value.id)
      .set('type', this.f['type'].value == null ? 0 : this.f['type'].value.id)
      .set('parent', this.f['parent'].value == null ? 0 : this.f['parent'].value.id)
      .set('activityType', this.f['activityType'].value == null ? 0 : this.f['activityType'].value.id)
      .set('name', this.f['name'].value)
      .set('bin', this.f['bin'].value)
      .set('ip', this.f['ip'].value)
      .set('confirmed', this.f['confirmed'].value)
      .set('pageIndex', this.paginator.pageIndex == null ? '0' : String(this.paginator.pageIndex))
      .set('pageSize', this.paginator.pageSize == null ? '10' : String(this.paginator.pageSize))
      .set('organizationSubject', this.f['organizationSubject'] == null ? 0 : this.f['organizationSubject'].value.id);

    this.loadCount(params);
    this.abonentService.getAll({params: params}).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'subjectBin': return item.subject.bin;
          case 'subjectName': return item.subject.name;
          case 'parentSubject': return item.subject.parent == null ? null : item.subject.parent.name;
          case 'organizationSubject': return item.subject.catalogueOrganization == null ? null : item.subject.catalogueOrganization.name;
          case 'region': return item.region == null ? null : item.region.name;
          case 'operatorAsn': return item.operator.asn;
          default: return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    });
  }

  loadCount(params: HttpParams) {
    this.abonentService.getCount({params: params}).subscribe(count => {
      this.paginator.length = count;
    });
  }

  getDate(ipRange: IpRange) {
    return ipRange.eshdiConnectedOperator ? ipRange.eshdiConnectDate : ipRange.eshdiDisconnectDate;
  }

  openDetails(url: string) {
    this.router.navigateByUrl(url);
  }

  //---------------------------------------------FILTER-----------------------------------------------------------------

  get f() {
    return this.filter.controls;
  }

  doFilter() {
    this.paginator.firstPage();
    this.loadData();
  }

  resetFilter() {
    this.router.navigate(['.'], { relativeTo: this.route, queryParams: {} }); //TODO rework
    this.f['parent'].disable();
    this.buildFilter();
    this.paginator.firstPage();
    this.loadData();
  }

  private buildFilter() {
    this.filter = this.formBuilder.group({
      subject: null,
      bin: '',
      name: '',
      type: this.types[0],
      parent: this.parents[0],
      activityType: this.activityTypes[0],
      ip: '',
      confirmed: 'all'
    });
  }

  onCheckChange(event: any, ipRange: IpRange) {
    this.isCheckbox = true;
    ipRange['confirmed'] = !!event.checked;
    if (this.changedIpRange.filter(x => x.id === ipRange.id).length !== 0) {
      let foundIndex = this.changedIpRange.findIndex(x => x.id === ipRange.id);
      this.changedIpRange[foundIndex] = ipRange;
    } else {
      this.changedIpRange.push(ipRange);
    }
  }

  saveConfirmation() {
    this.ipRangeService.changeConfirmationStatus(this.changedIpRange).subscribe(() => {
      this.notifierService.notify('success', 'Данные успешно сохранены');
    });
  }

  cancelConfirmation() {
    this.resetFilter();
    this.isCheckbox = false;
  }

  loadParentSubjects(type: SubjectType) {
    this.f['parent'].disable();
    this.f['parent'].setValue(this.parents[0]);
    this.f['activityType'].setValue(this.activityTypes[0]);
    if (type.name.indexOf('Другие ЮЛ') != -1) {
      this.f['activityType'].enable();
      return;
    }
    if (type.parent != null) {
      this.subjectService.getByType(type.parent).subscribe(data => {
        if (data.length > 0) {
          this.parents = this.parents.concat(data);
          this.f['parent'].enable();
          this.f['parent'].setValue(this.parents[0]);
        }
      });
    }
  }

  private loadBySubject(id: number) {
    this.subjectService.get(id).subscribe(data => {
      this.f['subject'].setValue(data);
      this.loadData();
    });
  }

  // private loadByCreatedBy(id: number) {
  //   this.f['createdBy'].setValue(id);
  //   this.loadData();
  // }

  getCurrentSubject() {
    let sbj = this.f['subject'].value;
    if (sbj != null && sbj.id != null)
      return sbj.bin + ' / ' + sbj.name;
    else
      return '';
  }

  selectSubject() {
    const dialogRef = this.dialog.open(SubjectSelectComponent);

    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        this.f['subject'].setValue(data);
      }
    });
  }


  //---------------------------------------------EXPORT-----------------------------------------------------------------

  _export() {
    this.isLoading = true;
    let params = new HttpParams()
      .set('subject', this.f['subject'].value == null ? 0 : this.f['subject'].value.id)
      .set('type', this.f['type'].value == null ? 0 : this.f['type'].value.id)
      .set('parent', this.f['parent'].value == null ? 0 : this.f['parent'].value.id)
      .set('activityType', this.f['activityType'].value == null ? 0 : this.f['activityType'].value.id)
      .set('name', this.f['name'].value)
      .set('bin', this.f['bin'].value)
      .set('ip', this.f['ip'].value);
    this.abonentService._export({params: params}).subscribe(data => {
      this.isLoading = false;
      let blob = new Blob([data], {type: 'application/vnd.ms-excel'});
      let url = window.URL.createObjectURL(blob);
      let filename = 'abonents.xls';
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);
    }, error => {
      this.isLoading = false;
    });
  }

  private checkRole() {
    this.permissionsService.permissions$.subscribe(() => {
      this.permissionsService.hasPermission(['OPERATOR_ADDITIONAL', 'OPERATOR']).then( result => {
        if (result) {
          if (this.displayedColumns.indexOf('action') < 0)
            this.displayedColumns.push('action');
        }
      });
    });
  }

}
