import { Injectable } from '@angular/core';
import {HttpService} from "./http.service";
import {EMPTY, Observable} from 'rxjs';
import {User} from "../model/user";
import {NgxPermissionsService} from "ngx-permissions";
import {TokenStorage} from "../security/token.storage";
import {OrganizationUser} from '../model/OrganizationUser';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private http: HttpService,
    private permissionsService: NgxPermissionsService,
    private tokenStorage: TokenStorage) { }

  public getCurrentUserInfo(): Observable<User> {
    return this.http.get<User>('/operator/user/current-user');
  }

  public getCurrentOrganizationUserInfo(): Observable<OrganizationUser> {
    return this.http.get<OrganizationUser>('/organization/user/current-user');
  }

  public get(id: number): Observable<any> {
    return this.http.get('/operator/user/' + id);
  }

  public changeCurrentUserPassword(password: string): Observable<any> {
    return this.http.post('/operator/user/change-password', password);
  }

  public changeCurrentUserEmail(email: string): Observable<any> {
    return this.http.post('/operator/user/change-email', email);
  }

  public updatePermissions() {
    // this.tokenStorage.clear();
    if (this.tokenStorage.getToken() != null) {
      this.getCurrentUserInfo().subscribe(user => {
        if (user) {
          this.permissionsService.flushPermissions();
          this.permissionsService.addPermission(user.role.name);
        } else {
          this.getCurrentOrganizationUserInfo().subscribe(organization => {
            this.permissionsService.flushPermissions();
            this.permissionsService.addPermission(organization.role.name);
          });
        }
      });
      // this.permissionsService.flushPermissions();
      // this.permissionsService.addPermission(user.role.name);
    }
  }
}
