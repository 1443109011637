import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators} from "@angular/forms";
import {Subject} from "../../model/subject";
import {SubjectType} from "../../model/subject-type";
import {ActivatedRoute} from "@angular/router";
import {SubjectService} from "../../service/subject.service";
import {NotifierService} from "angular-notifier";
import {Location} from "@angular/common";
import {ActivityType} from "../../model/activity-type";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-subject-edit',
  templateUrl: './subject-edit.component.html',
  styleUrls: ['./subject-edit.component.css']
})
export class SubjectEditComponent implements OnInit, OnDestroy {
  form: FormGroup;
  header: string;
  types: SubjectType[] = [];
  parents: Subject[] = [];
  activityTypes: ActivityType[] = [];
  isLoading = false;
  submitted = false;
  private translationSubscriptions: Subscription[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private service: SubjectService,
    private notifierService: NotifierService,
    private location: Location,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    this.buildForm(new Subject());
    // this.f['parent'].disable();

    this.service.getChildTypes().subscribe(data => {
      this.setNamesTranslation(data, 'COMPANIES.TYPES')
      this.types = data;
    });
    this.service.getActivityTypes().subscribe(data => {
      this.setNamesTranslation(data, 'COMPANIES.ACTIVITY_FORM')
      this.activityTypes = data;
      if (id != null) {
        this.setHeaderTranslation('SUBJECT.CHANGE_SUBJECT');
        this.loadData(+id);
      }
    });
  }

  setNamesTranslation(data: any, key: string) {
    this.translateService.stream(key).subscribe(translations => {
      data.forEach((item, index) => {
        if(Array.isArray(translations)) {
          item.name = translations[index];
        } else {
          item.name = translations;
        }
      });
    });
  }

  private setHeaderTranslation(translationKey: string) {
    const subscription = this.translateService.stream(translationKey).subscribe(translate => {
      this.header = translate;
    });
    this.translationSubscriptions.push(subscription);
  }

  loadData(id:number) {
    this.isLoading = true;
    this.service.get(id).subscribe(
      data => {
        if (data != null) {
          if (data.parent != null) {
            this.isLoading = false;
            this.service.getByType(data.type.parent).subscribe(parents => {
              if (parents.length > 0) {
                this.parents = parents;
                this.f['parent'].enable();
              }
              this.buildForm(data);
            });
          } else {
            this.buildForm(data);
          }
        }
      }, error=> {
        this.isLoading = false;
      }
    );
  }

  loadParentSubjects(type: SubjectType) {
    this.f['parent'].disable();
    this.form.controls['parent'].setValue(null);
    this.form.controls['activityType'].setValue(null);
    if (type.parent != null) {
      this.service.getByType(type.parent).subscribe(data => {
        if (data.length > 0) {
          this.parents = data;
          this.form.controls['parent'].setValue(data[0]);
          this.f['parent'].enable();
        }
      });
    }
  }

  private buildForm(editableSubject: Subject) {
    this.form = this.formBuilder.group({
      id: [editableSubject.id],
      bin: [editableSubject.bin, [
        Validators.required,
        Validators.minLength(12),
        Validators.pattern('^[0-9]+$')
      ]],
      name: [editableSubject.name, [
        Validators.required,
        // Validators.pattern('^[а-яА-ЯёЁa-zA-Z0-9 ]+$')
      ]],
      // contactName: [editableSubject.contactName, Validators.required],
      email: [editableSubject.email, [Validators.email, Validators.required]],
      phoneNumber: [editableSubject.phoneNumber, [
        Validators.required,
        Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')
      ]],
      address: [editableSubject.address, Validators.required]
      // type: [this.findType(editableSubject.type == null ? 0 : editableSubject.type.id),
      //   Validators.required
      // ],
      // parent: [this.findParent(editableSubject.parent == null ? 0 : editableSubject.parent.id)],
      // activityType: [this.findActivityType(editableSubject.activityType == null ? 0 : editableSubject.activityType.id)]
    });
  }

  private findType(id: number): SubjectType {
    return this.types.find(t => t.id == id);
  }

  private findParent(id: number): Subject {
    return this.parents.find(p => p.id == id);
  }

  private findActivityType(id: number): ActivityType {
    return this.activityTypes.find(t => t.id == id);
  }

  onSubmit() {
    // this.f['type'].markAsTouched();
    this.f['bin'].markAsTouched();
    this.f['name'].markAsTouched();
    // this.f['contactName'].markAsTouched();
    this.f['phoneNumber'].markAsTouched();
    this.f['email'].markAsTouched();
    this.f['address'].markAsTouched();

    if (this.form.invalid)
      return;

    this.isLoading = true;
    this.service.edit(this.form.value).subscribe(
      () => {
        this.isLoading = false;
        let message: string = this.translateService.instant('BASIC.DATA_SAVED');
        this.notifierService.notify('success', message);
        this.location.back();
      }, error => {
        this.isLoading = false;
      }
    );
  }

  get f() {
    return this.form.controls;
  }

  ngOnDestroy() {
    this.translationSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
