import {Injectable} from '@angular/core';
import {EnvConfig} from './env-config';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentLoaderService {
  private envConfig: EnvConfig;

  constructor(private readonly http: HttpClient) {}

  async loadEnvConfig(configPath: string): Promise<void> {
    this.envConfig = await this.http.get<EnvConfig>(configPath).toPromise();
  }

  getEnvConfig(): EnvConfig {
    return this.envConfig;
  }
}
