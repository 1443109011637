import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource, MatSort, MatPaginator, MatDialog} from "@angular/material";
import {EshdiService} from "../service/eshdi.service";
import {IpRange} from "../model/ip-range";
import {SubjectType} from "../model/subject-type";
import {Subject} from "../model/subject";
import {ActivityType} from "../model/activity-type";
import {FormGroup, FormBuilder} from "@angular/forms";
import {HttpParams} from "@angular/common/http";
import {SubjectSelectComponent} from "../subject/subject-select/subject-select.component";
import {SubjectService} from "../service/subject.service";
import {TranslateService} from "@ngx-translate/core";

let emptyType = new SubjectType();
emptyType.id = 0;
emptyType.name = 'Все';
let emptyParent = new Subject();
emptyParent.id = 0;
emptyParent.bin = '0';
emptyParent.name = 'Все';
let emptyActivityType = new ActivityType();
emptyActivityType.id = 0;
emptyActivityType.name = 'Все';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {
  displayedColumns: string[] = ['id', 'subject', 'organizationSubject', 'region', 'ipRange',
    'lastCheck', 'eshdiCo', 'eshdi', 'eshdiDate', 'status', 'action'];
  dataSource: MatTableDataSource<IpRange>;

  isLoading = false;
  pageSizeArray = [10, 25, 50, 100];

  filter: FormGroup;
  types: SubjectType[] = [emptyType];
  parents: Subject[] = [emptyParent];
  activityTypes: ActivityType[] = [emptyActivityType];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  constructor(
    private subjectService: SubjectService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialog,
    private eshdiService: EshdiService,
    private translateService: TranslateService) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.buildFilter();
    this.f['parent'].disable();

    this.subjectService.getAllTypes().subscribe(data => {
      this.setNamesTranslation(data, 'COMPANIES.TYPES');
      this.types = this.types.concat(data);
    });
    this.subjectService.getActivityTypes().subscribe(data => {
      this.setNamesTranslation(data, 'COMPANIES.ACTIVITY_FORM');
      this.activityTypes = this.activityTypes.concat(data);
    });
    this.loadData();
    this.setNamesTranslation([emptyType, emptyParent, emptyActivityType], 'OTHER.ALL');
  }

  setNamesTranslation(data: any, key: string) {
    this.translateService.stream(key).subscribe(translations => {
      data.forEach((item, index) => {
        if(Array.isArray(translations)) {
          item.name = translations[index];
        } else {
          item.name = translations;
        }
      });
    });
  }

  loadData() {
    this.isLoading = true;
    let params = new HttpParams()
      .set('subject', this.f['subject'].value == null ? 0 : this.f['subject'].value.id)
      .set('type', this.f['type'].value == null ? 0 : this.f['type'].value.id)
      .set('parent', this.f['parent'].value == null ? 0 : this.f['parent'].value.id)
      .set('activityType', this.f['activityType'].value == null ? 0 : this.f['activityType'].value.id)
      .set('name', this.f['name'].value)
      .set('bin', this.f['bin'].value)
      .set('eshdi', this.f['eshdi'].value)
      .set('eshdi_co', this.f['eshdi_co'].value)
      .set('ip', this.f['ip'].value)
      .set('pageIndex', this.paginator.pageIndex == null ? '0' : String(this.paginator.pageIndex))
      .set('pageSize', this.paginator.pageSize == null ? '10' : String(this.paginator.pageSize))
      .set('organizationSubject', this.f['organizationSubject'] == null ? 0 : this.f['organizationSubject'].value.id);

    //TODO reuse "params" for export
    this.loadCount(params);
    this.eshdiService.loadErrorIpRanges({params: params}).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'subject':
            return item.abonent == null
              ? null : item.abonent.subject == null
              ? null : item.abonent.subject.name;
          case 'orgranizationSubject':
            return item.abonent == null
              ? null : item.abonent.subject == null
              ? null : item.abonent.subject.catalogueOrganization == null
              ? null : item.abonent.subject.catalogueOrganization.name;
          case 'region':
            return item.abonent == null
              ? null : item.abonent.region == null
              ? null : item.abonent.region.name;
          default: return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    }, error => {
      this.isLoading = false;
    });
  }

  loadCount(params: HttpParams) {
    this.eshdiService.loadErrorIpRangesCount({params: params}).subscribe(count => {
      this.paginator.length = count;
    });
  }

  isOperatorError(ipRange: IpRange): boolean {
    return !ipRange.eshdiConnectedOperator && ipRange.eshdiConnectedHardware;
  }

  //---------------------------------------------FILTER-----------------------------------------------------------------

  get f() {
    return this.filter.controls;
  }

  doFilter() {
    this.paginator.firstPage();
    this.loadData();
  }

  resetFilter () {
    this.f['parent'].disable();
    this.buildFilter();
    this.paginator.firstPage();
    this.loadData();
  }

  private buildFilter() {
    this.filter = this.formBuilder.group({
      subject: null,
      bin: '',
      name: '',
      type: this.types[0],
      parent: this.parents[0],
      activityType: this.activityTypes[0],
      ip: '',
      eshdi: 'all',
      eshdi_co: 'all'
    });
  }

  loadParentSubjects(type: SubjectType) {
    this.f['parent'].disable();
    this.f['parent'].setValue(this.parents[0]);
    this.f['activityType'].setValue(this.activityTypes[0]);
    if (type.name.indexOf('Другие ЮЛ') != -1) {
      this.f['activityType'].enable();
      return;
    }
    if (type.parent != null) {
      this.subjectService.getByType(type.parent).subscribe(data => {
        if (data.length > 0) {
          this.parents = this.parents.concat(data);
          this.f['parent'].enable();
          this.f['parent'].setValue(this.parents[0]);
        }
      });
    }
  }

  getCurrentSubject() {
    let sbj = this.f['subject'].value;
    if (sbj != null && sbj.id != null)
      return sbj.bin + ' / ' + sbj.name;
    else
      return '';
  }

  selectSubject() {
    const dialogRef = this.dialogRef.open(SubjectSelectComponent);

    dialogRef.afterClosed().subscribe(data => {
      if (data != null) {
        this.f['subject'].setValue(data);
      }
    });
  }


  //---------------------------------------------EXPORT-----------------------------------------------------------------
  _export() {
    this.isLoading = true;
    let params = new HttpParams()
      .set('subject', this.f['subject'].value == null ? 0 : this.f['subject'].value.id)
      .set('type', this.f['type'].value == null ? 0 : this.f['type'].value.id)
      .set('parent', this.f['parent'].value == null ? 0 : this.f['parent'].value.id)
      .set('activityType', this.f['activityType'].value == null ? 0 : this.f['activityType'].value.id)
      .set('name', this.f['name'].value)
      .set('bin', this.f['bin'].value)
      .set('eshdi', this.f['eshdi'].value)
      .set('eshdi_co', this.f['eshdi_co'].value)
      .set('ip', this.f['ip'].value);

    this.eshdiService.errorExport({params: params}).subscribe(data => {
      this.isLoading = false;
      let blob = new Blob([data], {type: 'application/vnd.ms-excel'});
      let url = window.URL.createObjectURL(blob);
      let filename = 'errorRanges.xls';
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);
    }, error => {
      this.isLoading = false;
    });
  }

  getDate(ipRange: IpRange) {
    return ipRange.eshdiConnectedOperator ? ipRange.eshdiConnectDate : ipRange.eshdiDisconnectDate;
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }
}
