import {Component, Inject, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {OrganizationUser} from '../../model/OrganizationUser';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {passwordMatchValidator} from '../../util/password-match-validator';
import {OrganizationUserService} from '../../service/organization-user-service';
import {NotifierService} from 'angular-notifier';
import {Location} from '@angular/common';
import {CustomErrorStateMatcher} from '../../util/custom-error-state-matcher';

@Component({
  selector: 'app-profile-organization-edit',
  templateUrl: './profile-organization-edit.component.html',
  styleUrls: ['./profile-organization-edit.component.css']
})
export class ProfileOrganizationEditComponent implements OnInit {
  organization: OrganizationUser;
  form: FormGroup;
  errorMatcher = new CustomErrorStateMatcher;

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<ProfileOrganizationEditComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: OrganizationUser,
    private formBuilder: FormBuilder,
    private userService: OrganizationUserService,
    private notifierService: NotifierService,
    private location: Location
  ) { }

  ngOnInit() {
    this.organization = this.data;
    this.buildForm(this.organization);
  }

  private buildForm(user: OrganizationUser) {
    this.form = this.formBuilder.group({
      id: user.id,
      // username: [user.username, [
      //   Validators.required,
      //   Validators.minLength(4),
      //   // Validators.pattern('^[a-zA-Z][a-zA-Z0-9-_]+$')
      //   Validators.email
      // ]],
      // fullName: [user.fullName, [
      //   Validators.minLength(4),
      // ]],
      fullName: [user.fullName, [
        Validators.minLength(4)
      ]],
      email: [user.email, [
        Validators.email
      ]],
      phone: [user.phone, [
        Validators.pattern('^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$')
      ]],
      password: ['', [
        Validators.minLength(8),
        Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\\d$@$!%*?&].{8,}')
      ]],
      passwordConfirm: ''
    }, {validator: passwordMatchValidator});
  }

  get f() {
    return this.form.controls;
  }

  onSubmit() {
    if (this.form.invalid) {
      return;
    }
    this.userService.edit(this.form.value).subscribe(
    () => {
      this.notifierService.notify('success', 'BASIC.DATA_SAVED');
      this.location.back();
    });
  }

}
