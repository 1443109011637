import {AfterViewInit, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatSort, MatPaginator, MatTableDataSource} from "@angular/material";
import {IpRange} from "../model/ip-range";
import {EshdiService} from "../service/eshdi.service";
import {HttpParams} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-eshdi',
  templateUrl: './eshdi.component.html',
  styleUrls: ['./eshdi.component.css']
})
export class EshdiComponent implements OnInit, OnDestroy, AfterViewInit {
  displayedColumns: string[] = ['id', 'ipRange', 'vdom', 'lastCheck', 'status'];
  dataSource: MatTableDataSource<IpRange>;

  pageSizeArray = [10, 25, 50, 100];
  isLoading = false;
  translationSubscriptions: Subscription[] = [];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  filter = {
    status: 'all',
    ip: ''
  };

  constructor(
    private eshdiService: EshdiService,
    private translateService: TranslateService,
    private changeDetector: ChangeDetectorRef) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
  }

  ngAfterViewInit(): void {
    this.translateService.onLangChange.subscribe(() => {
      this.changeDetector.detectChanges();
    });
  }

  ngOnInit() {
    this.filter.status = 'all';
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    let params = new HttpParams()
      .set('status', String(this.filter.status))
      .set('ip', this.filter.ip)
      .set('pageIndex', this.paginator.pageIndex == null ? '0' : String(this.paginator.pageIndex))
      .set('pageSize', this.paginator.pageSize == null ? '10' : String(this.paginator.pageSize));

    this.loadCount(params);
    this.eshdiService.loadIpRanges({params: params}).subscribe(data => {
      this.dataSource = new MatTableDataSource(data);
      this.dataSource.sortingDataAccessor = (item, property) => {
        switch(property) {
          case 'ipRange': return item.value;
          case 'vdom': return item.virtualDomain == null ? null : item.virtualDomain.name;
          case 'lastCheck': return item.eshdiLastCheckDate;
          case 'status': return item.active;
          default: return item[property];
        }
      };
      this.dataSource.sort = this.sort;
      this.isLoading = false;
    });
  }

  loadCount(params: HttpParams) {
    this.eshdiService.getCount({params: params}).subscribe(count => {
      this.paginator.length = count;
    });
  }

  doFilter() {
    this.paginator.firstPage();
    this.loadData();
  }

  resetFilter() {
    this.filter.status = null;
    this.filter.ip = '';
    this.paginator.firstPage();
    this.loadData();
  }

  _export() {
    this.isLoading = true;
    this.eshdiService._export().subscribe(data => {
      this.isLoading = false;
      let blob = new Blob([data], {type: 'application/vnd.ms-excel'});
      let url = window.URL.createObjectURL(blob);
      let filename = 'ESHDI.xls';
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);
    });
  }

  ngOnDestroy() {
    this.translationSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
