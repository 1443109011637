import {SubjectType} from "./subject-type";
import {ActivityType} from "./activity-type";
import {CatalogueOrganization} from "./CatalogueOrganization";

export class Subject {
  id: number;
  bin: string;
  name: string;
  contactName: string;
  email: string;
  phoneNumber: string;
  address: string;
  type: SubjectType;
  activityType: ActivityType;
  parent: Subject;
  catalogueOrganization: CatalogueOrganization;
}
