import {Operator} from "./operator";
import {VirtualDomain} from "./virtual-domain";
import {Abonent} from "./abonent";
import {ErrorReport} from "./error-report";
import {IpAddress} from './ip-address';

export class IpRange {
  id: number;
  created: string;
  updated: string;
  value: string;
  ipv6: boolean;
  eshdiConnectedOperator: boolean = true;
  eshdiConnectedHardware: boolean;
  confirmed: boolean;
  eshdiConnectDate: string;
  eshdiDisconnectDate: string;
  eshdiLastCheckDate: string;
  virtualDomain: VirtualDomain;
  operator: Operator;
  abonent: Abonent;
  errors: ErrorReport[];
  ipAddresses: IpAddress[];
  active: boolean;
}
