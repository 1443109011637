import { Injectable } from '@angular/core';
import {Operator} from "../model/operator";
import {Observable} from "rxjs";
import {HttpService} from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class OperatorService {
  constructor(private http: HttpService) { }

  public getAll(): Observable<Operator[]> {
    return this.http.get<Operator[]>('/operator/all');
  }

  public edit(data: Operator): Observable<any> {
    return this.http.post('/operator', data);
  }

  public remove(id: number): Observable<any> {
    return this.http.post('/operator/delete', id);
  }

  public get(id: number): Observable<Operator> {
    return this.http.get('/operator/' + id);
  }
}
