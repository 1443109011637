import { Injectable } from '@angular/core';
import {IpRange} from "../model/ip-range";
import {Observable} from "rxjs";
import {HttpService} from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class IpRangeService {

  constructor(private http: HttpService) { }

  public get(options?: Object): Observable<IpRange[]> {
    return this.http.get('/ip/operator', options);
  }

  public getCount(options?: Object): Observable<number> {
    return this.http.get('/ip/operator/count', options);
  }
  public changeConfirmationStatus(ipRange: IpRange[]): Observable<IpRange> {
    return this.http.post('/ip/confirmStatus', ipRange);
  }
}
