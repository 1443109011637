import { Injectable } from '@angular/core';
import {OrganizationUser} from '../model/OrganizationUser';
import {HttpService} from './http.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationUserService {

  constructor(private http: HttpService) { }

  public edit(user: OrganizationUser): any {
    return this.http.post('/organization/user/editProfile', user);
  }
}
