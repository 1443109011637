import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {EnvironmentLoaderService} from '../enviroment-loader.service';
import {environment} from '../../environments/environment';

const localUrl = "http://localhost:9010";
const testUrl = "http://10.10.27.39:9010";
const publicUrl = "https://catalog.sts.kz:9010";

@Injectable({
  providedIn: 'root'
})
export class HttpService {
  baseUrl = 'https://catalog.sts.kz:9010';

  constructor(private http: HttpClient,
              private ensService: EnvironmentLoaderService) {
    this.baseUrl = environment.production ? this.ensService.getEnvConfig().serverUrl : this.baseUrl;
  }

  get<T>(url: string, options?: Object): any {
    return this.http.get<T>(this.baseUrl + url, options);
  }

  post(url: string, data: any): any {
    return this.http.post(this.baseUrl  + url, data);
  }
}
