import {Injectable} from "@angular/core";
import {HttpInterceptor} from "@angular/common/http";
import {HttpRequest} from "@angular/common/http";
import {HttpHandler} from "@angular/common/http";
import {Observable} from "rxjs";
import {HttpEvent} from "@angular/common/http";
import {tap} from "rxjs/internal/operators";
import {HttpResponse} from "@angular/common/http";
import {ErrorHandler} from "../service/error-handler.service";
import {TokenStorage} from "./token.storage";

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable()
export class Interceptor implements HttpInterceptor {

  constructor(
    private tokenStorage: TokenStorage,
    private errorHandler: ErrorHandler) {}


  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    if (this.tokenStorage.getToken() != null) {
      if (req.url.indexOf('export') != -1)
        authReq = req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.tokenStorage.getToken()), responseType: 'blob'});
      else
        authReq = req.clone({headers: req.headers.set(TOKEN_HEADER_KEY, 'Bearer ' + this.tokenStorage.getToken())});
    }
    return next.handle(authReq).pipe(
      tap(
        event => ok => event instanceof HttpResponse ? 'succeeded' : '',
        error => {
          this.errorHandler.handle(error);
        }
      )
    );
  }
}
