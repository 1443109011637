import {Component, OnInit, Input} from '@angular/core';
import {SubjectDetailComponent} from "../subject-detail/subject-detail.component";
import {MatDialog} from "@angular/material";
import {Subject} from "../../model/subject";

@Component({
  selector: 'subject-link',
  templateUrl: './subject-link.component.html',
  styleUrls: ['./subject-link.component.css']
})
export class SubjectLinkComponent implements OnInit {
  @Input()
  subject: Subject;

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  showDetail() {
    if (this.subject.id == null)
      return;
    this.dialog.open(SubjectDetailComponent, {
      data: String(this.subject.id)
    });
  }
}
