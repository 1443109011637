import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpService} from "./http.service";
import {Event} from "../model/event";

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(private http: HttpService) { }

  public getAll(options?: Object): Observable<Event[]> {
    return this.http.get('/event/operator/all', options);
  }

  public getCount(options?: Object): Observable<number> {
    return this.http.get('/event/operator/all/count', options);
  }
}
