import { NgModule } from '@angular/core';
import {RouterModule, Routes} from "@angular/router";
import {LoginComponent} from "./login/login.component";
import {AuthGuard} from "./security/auth.guard";
import {AbonentListComponent} from "./abonent/abonent-list/abonent-list.component";
import {NotFoundComponent} from "./not-found/not-found.component";
import {ProfileComponent} from "./profile/profile.component";
import {AbonentEditComponent} from "./abonent/abonent-edit/abonent-edit.component";
import {SubjectListComponent} from "./subject/subject-list/subject-list.component";
import {HistoryComponent} from "./history/history.component";
import {SubjectEditComponent} from "./subject/subject-edit/subject-edit.component";
import {EshdiComponent} from "./eshdi/eshdi.component";
import {ReportComponent} from "./report/report.component";
import {AbonentImportComponent} from "./abonent/abonent-import/abonent-import.component";
import {EventListComponent} from "./event/event-list/event-list.component";
import {AbonentDetailComponent} from "./abonent/abonent-detail/abonent-detail.component";
import {AbonentEditGuard} from "./security/abonent.edit.guard";

const appRoutes: Routes = [
  {path: '', redirectTo: 'abonent/all', pathMatch: 'full', canActivate: [AuthGuard]},
  {path: 'abonent/all', component: AbonentListComponent, canActivate: [AuthGuard]},
  {path: 'abonent-detail/:id', component: AbonentDetailComponent, canActivate: [AuthGuard]},
  {path: 'abonent/import', component: AbonentImportComponent, canActivate: [AuthGuard]},
  {path: 'abonent', component: AbonentEditComponent, canActivate: [AuthGuard]},
  {path: 'abonent/:id', component: AbonentEditComponent, canActivate: [AuthGuard, AbonentEditGuard]},
  {path: 'subject/all', component: SubjectListComponent, canActivate: [AuthGuard]},
  {path: 'subject', component: SubjectEditComponent, canActivate: [AuthGuard]},
  // {path: 'subject/:id', component: SubjectEditComponent, canActivate: [AuthGuard]},
  {path: 'profile', component: ProfileComponent, canActivate: [AuthGuard]},
  {path: 'eshdi', component: EshdiComponent, canActivate: [AuthGuard]},
  {path: 'report', component: ReportComponent, canActivate: [AuthGuard]},
  {path: 'history', component: HistoryComponent, canActivate: [AuthGuard]},
  {path: 'events', component: EventListComponent, canActivate: [AuthGuard]},
  {path: 'login', component: LoginComponent},
  {path: '**', component: NotFoundComponent, canActivate: [AuthGuard]}
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes)
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
