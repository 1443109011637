import { BrowserModule } from '@angular/platform-browser';
import {APP_INITIALIZER, NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import {MaterialModule} from "./util/material.module";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {NgxPermissionsModule, NgxPermissionsRestrictStubModule} from 'ngx-permissions';
import {NotifierModule} from "angular-notifier";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {AppRoutingModule} from "./app-routing.module";
import {HTTP_INTERCEPTORS} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {Interceptor} from "./security/interceptor";
import {AuthService} from "./service/auth.service";
import {HttpService} from "./service/http.service";
import {TokenStorage} from "./security/token.storage";
import { AbonentListComponent } from './abonent/abonent-list/abonent-list.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NavigationComponent } from './navigation/navigation.component';
import { ProfileComponent } from './profile/profile.component';
import { GoBackComponent } from './go-back/go-back.component';
import { AbonentEditComponent } from './abonent/abonent-edit/abonent-edit.component';
import { DeleteDialogComponent } from './delete-dialog/delete-dialog.component';
import { SubjectListComponent } from './subject/subject-list/subject-list.component';
import { HistoryComponent } from './history/history.component';
import { SubjectEditComponent } from './subject/subject-edit/subject-edit.component';
import { EshdiComponent } from './eshdi/eshdi.component';
import { ReportComponent } from './report/report.component';
import { SubjectDetailComponent } from './subject/subject-detail/subject-detail.component';
import {OwlNativeDateTimeModule, OwlDateTimeModule} from "ng-pick-datetime";
import { SubjectSelectComponent } from './subject/subject-select/subject-select.component';
import { AbonentImportComponent } from './abonent/abonent-import/abonent-import.component';
import { EventListComponent } from './event/event-list/event-list.component';
import { AbonentDetailComponent } from './abonent/abonent-detail/abonent-detail.component';
import { SubjectLinkComponent } from './subject/subject-link/subject-link.component';
import { UserDetailComponent } from './user/user-detail/user-detail.component';
import { UserLinkComponent } from './user/user-link/user-link.component';
import {HttpLoaderFactory} from "./util/http-loader-factory";
import {MissingTranslationHandler, TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {MissingTranslationService} from "./service/missing-translation.service";
import { ProfileOrganizationEditComponent } from './profile/profile-organization-edit/profile-organization-edit.component';
import {LanguageSelectComponent} from "./language-select/language-select.component";
import {MatDatepickerModule} from "@angular/material/datepicker";
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatNativeDateModule} from '@angular/material/core';
import {DatePipe} from '@angular/common';
import {CustomDateAdapter} from "./util/custom-date-adapter";
import {EnvironmentLoaderService} from './enviroment-loader.service';

const initAppFn = (envService: EnvironmentLoaderService) => {
  return () => envService.loadEnvConfig('/assets/config/app-config.json');
};


const CUSTOM_DATE_FORMATS = {
  parse: {
    dateInput: {month: 'short', year: 'numeric', day: 'numeric'}
  },
  display: {
    dateInput: 'input',
    monthYearLabel: {year: 'numeric', month: 'short'},
    dateA11yLabel: {year: 'numeric', month: 'long', day: 'numeric'},
    monthYearA11yLabel: {year: 'numeric', month: 'long'},
  }
};

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AbonentListComponent,
    NotFoundComponent,
    HeaderComponent,
    FooterComponent,
    DashboardComponent,
    NavigationComponent,
    ProfileComponent,
    GoBackComponent,
    AbonentEditComponent,
    DeleteDialogComponent,
    SubjectListComponent,
    HistoryComponent,
    SubjectEditComponent,
    EshdiComponent,
    ReportComponent,
    SubjectDetailComponent,
    SubjectSelectComponent,
    AbonentImportComponent,
    EventListComponent,
    AbonentDetailComponent,
    SubjectLinkComponent,
    UserDetailComponent,
    UserLinkComponent,
    ProfileOrganizationEditComponent,
    LanguageSelectComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    NgxPermissionsModule.forRoot(),
    NotifierModule.withConfig({
      position: {
        horizontal: {
          position: 'middle'
        },
        vertical: {
          position: 'top',
        }
      }
    }),
    AppRoutingModule,
    NgxPermissionsRestrictStubModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      missingTranslationHandler: { provide: MissingTranslationHandler, useClass: MissingTranslationService },
      useDefaultLang: false,
    }),
    MatDatepickerModule,
    MatNativeDateModule,
  ],
  providers: [TokenStorage, AuthService, HttpService, DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
    }, EnvironmentLoaderService,
    {
      provide: APP_INITIALIZER,
      useFactory: initAppFn,
      multi: true,
      deps: [EnvironmentLoaderService],
    },
    {
      provide: MAT_DATE_LOCALE, useValue: 'ru-RU'
    },
    {
      provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS
    },
    {
      provide: DateAdapter, useClass: CustomDateAdapter
    },
  ],
  entryComponents:[SubjectSelectComponent, UserDetailComponent, SubjectDetailComponent, DeleteDialogComponent, ProfileOrganizationEditComponent],
  bootstrap: [AppComponent]
})
export class AppModule { }
