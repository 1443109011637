import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {Location} from "@angular/common";
import {NotifierService} from "angular-notifier";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class ErrorHandler {

  constructor(
    private router: Router,
    private authService: AuthService,
    private location: Location,
    private notifierService: NotifierService
  ) { }

  public handle(error: any): void {
    if (error.status === 401) {
      this.notifierService.notify('error', 'Время сеанса истекло');
      this.authService.out();
      this.router.navigate(['login']);
    } else
    if (error.status === 403) {
      this.notifierService.notify('error', 'Доступ запрещен');
      this.location.back();
    } else
    if (error.status === 0) {
      this.notifierService.notify('error', 'Проблемы соединения с сервером');
    } else {
      this.notifierService.notify('error', typeof error.error === 'string' ? error.error : 'Ошибка');
    }
  }
}
