export class Operator {
  id: number;
  bin: string;
  name: string;
  asn: string;
  address: string;
  contactName: string;
  contactPhone: string;
  contactEmail: string;
}

