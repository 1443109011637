import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, Router} from '@angular/router';
import {TokenStorage} from "./token.storage";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(
    private tokenStorage: TokenStorage,
    private router: Router,
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot) {
    if (this.tokenStorage.getToken() == null) {
      this.router.navigate(['login']);
      return false;
    }
    return true;
  }
}
