import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import {SubjectService} from "../../service/subject.service";
import {Subject} from "../../model/subject";
import {Router} from "@angular/router";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {IpRange} from "../../model/ip-range";
import {HttpParams} from "@angular/common/http";
import {AbonentService} from "../../service/abonent.service";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-subject-detail',
  templateUrl: './subject-detail.component.html',
  styleUrls: ['./subject-detail.component.css']
})
export class SubjectDetailComponent implements OnInit, OnDestroy {
  header: string;
  subject: Subject;
  isLoading = false;
  private id = 0;
  abonentCount = 0;
  private translationSubscriptions: Subscription[] = [];

  constructor(
    private service: SubjectService,
    private router: Router,
    public dialogRef: MatDialogRef<SubjectDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: string,
    private abonentService: AbonentService,
    private translateService: TranslateService) {
    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });
  }

  ngOnInit() {
    this.setHeaderTranslation('BASIC.LOADING');
    this.subject = new Subject();
    this.id = +this.data;
    this.loadData();
  }

  loadData() {
    this.isLoading = true;
    this.service.get(this.id).subscribe(data => {
      this.subject = data;
      this.header = data.name;
      this.isLoading = false;
      this.loadAbonentCount();
    });
  }

  private loadAbonentCount() {
    let params = new HttpParams()
      .set('subject', String(this.id));
    this.abonentService.getCount({params: params}).subscribe(count => {
      this.abonentCount = count;
    });
  }

  getDate(ipRange: IpRange) {
    return ipRange.eshdiConnectedOperator ? ipRange.eshdiConnectDate == null ? '' : ipRange.eshdiConnectDate
      : ipRange.eshdiDisconnectDate == null ? '' : ipRange.eshdiDisconnectDate;
  }

  showAbonents() {
    if (this.abonentCount == 0)
      return;
    this.router.navigate(['abonent/all'], { queryParams: { subject: this.id } });
  }

  private setHeaderTranslation(translationKey: string) {
    const subscription = this.translateService.stream(translationKey).subscribe(translate => {
      this.header = translate;
    });
    this.translationSubscriptions.push(subscription);
  }

  ngOnDestroy() {
    this.translationSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }
}
