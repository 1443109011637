import { Component } from '@angular/core';
import {UserService} from "./service/user.service";
import {TranslateService} from "@ngx-translate/core";
import {Title} from "@angular/platform-browser";
import {environment} from "../environments/environment";
import {IdleTimeoutService} from './service/idle-timeout.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  constructor(private userService: UserService,
              private translateService: TranslateService,
              private titleService: Title,
              private idleTimeoutService: IdleTimeoutService) {
    userService.updatePermissions();
    this.translateService.onLangChange.subscribe(() => {
      this.setTitle();
    });
    this.setTitle();
  }

  ngOnInit(): void {
    const storedLanguage = localStorage.getItem('selectedLanguage');
    this.translateService.use(storedLanguage || environment.defaultLocale);
    this.idleTimeoutService.startMonitoring();
  }

  setTitle() {
    this.translateService.get('NETWORK.CATALOG').subscribe((res: string) => {
      this.titleService.setTitle(res);
    });
  }
}
