import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  MatButtonModule,
  MatToolbarModule,
  MatCardModule,
  MatDialogModule,
  MatInputModule,
  MatTableModule,
  MatSidenavModule,
  MatTreeModule,
  MatPaginatorModule,
  MatSortModule,
  MatMenuModule,
  MatIconModule,
  MatListModule,
  MatSelectModule,
  MatGridListModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatStepperModule,
  MatFormFieldModule,
  MatRippleModule,
  MatProgressSpinnerModule,
  MatProgressBarModule,
  MatRadioModule,
  MatTabsModule,
  MatChipList,
  MatChip,
  MatChipsModule
} from '@angular/material';

const modules = [
  CommonModule,
  MatButtonModule,
  MatToolbarModule,
  MatTableModule,
  MatTreeModule,
  MatListModule,
  MatSelectModule,
  MatGridListModule,
  MatCardModule,
  MatInputModule,
  MatDialogModule,
  MatSidenavModule,
  MatPaginatorModule,
  MatSortModule,
  MatMenuModule,
  MatIconModule,
  MatCheckboxModule,
  MatTabsModule,
  MatRadioModule,
  MatFormFieldModule,
  MatRippleModule,
  MatStepperModule,
  MatExpansionModule,
  MatProgressBarModule,
  MatProgressSpinnerModule,
  MatChipsModule
];

@NgModule({
  imports: [modules],
  exports: [modules]
})
export class MaterialModule { }
