import {Operator} from "./operator";
import {Role} from "./role";
import {Region} from "./region";
import {IpRange} from "./ip-range";

export class User {
  id: number;
  fullName: string;
  name: string;
  username: string;
  role: Role;
  operator: Operator;
  email: string;
  registered: string;
  regions: Region[];
  city: string;
  address: string;
  prefixList: IpRange[];
}
