  import {Component, OnInit, ViewChild} from '@angular/core';
import { MatSort, MatPaginator, MatTableDataSource, MatDialogRef} from "@angular/material";
import {SubjectService} from "../../service/subject.service";
import {Subject} from "../../model/subject";
import {FormGroup, FormBuilder} from "@angular/forms";
import {SubjectType} from "../../model/subject-type";
import {ActivityType} from "../../model/activity-type";
import {HttpParams} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";

let emptyType = new SubjectType();
emptyType.id = 0;
emptyType.name = 'Все';
let emptyActivityType = new ActivityType();
emptyActivityType.id = 0;
emptyActivityType.name = 'Все';
let emptyParent = new Subject();
emptyParent.id = 0;
emptyParent.bin = '0';
emptyParent.name = 'Все';

@Component({
  selector: 'app-subject-select',
  templateUrl: './subject-select.component.html',
  styleUrls: ['./subject-select.component.css']
})
export class SubjectSelectComponent implements OnInit {
  displayedColumns: string[] = ['id', 'type', 'activityType', 'name', 'bin', 'parentSubject', 'select'];
  dataSource: MatTableDataSource<Subject>;
  isLoading = false;
  pageSizeArray = [10, 25, 50, 100];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  filter: FormGroup;
  types: SubjectType[] = [emptyType];
  parents: Subject[] = [emptyParent];
  activityTypes: ActivityType[] = [emptyActivityType];

  constructor(
    public dialogRef: MatDialogRef<SubjectSelectComponent>,
    private service: SubjectService,
    private formBuilder: FormBuilder,
    private translateService: TranslateService
  ) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.buildFilter();
    this.loadData();

    this.f['parent'].disable();

    this.service.getAllTypes().subscribe(data => {
      this.setNamesTranslation(data, 'COMPANIES.TYPES')
      this.types = this.types.concat(data);
    });
    this.service.getActivityTypes().subscribe(data => {
      this.setNamesTranslation(data, 'COMPANIES.ACTIVITY_FORM')
      this.activityTypes = this.activityTypes.concat(data);
    });
    this.setNamesTranslation([emptyType, emptyParent, emptyActivityType], 'OTHER.ALL');
  }

  setNamesTranslation(data: any, key: string) {
    this.translateService.stream(key).subscribe(translations => {
      data.forEach((item, index) => {
        if(Array.isArray(translations)) {
          item.name = translations[index];
        } else {
          item.name = translations;
        }
      });
    });
  }

  loadData() {
    this.isLoading = true;
    let params = new HttpParams()
      .set('type', this.f['type'].value == null ? 0 : this.f['type'].value.id)
      .set('parent', this.f['parent'].value == null ? 0 : this.f['parent'].value.id)
      .set('activityType', this.f['activityType'].value == null ? 0 : this.f['activityType'].value.id)
      .set('name', this.f['name'].value)
      .set('bin', this.f['bin'].value)
      .set('pageIndex', this.paginator.pageIndex == null ? '0' : String(this.paginator.pageIndex))
      .set('pageSize', this.paginator.pageSize == null ? '10' : String(this.paginator.pageSize));

    this.loadCount(params);
    this.service.getAll({params: params}).subscribe(
      data => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'type': return item.type == null ? null : item.type.name;
            case 'activityType': return item.activityType == null ? null : item.activityType.name;
            case 'parentSubject': return item.parent == null ? null : item.parent.name;
            default: return item[property];
          }
        };
        this.dataSource.sort = this.sort;
        this.isLoading = false;
      }
    );
  }

  loadCount(params: HttpParams) {
    this.service.getCount({params: params}).subscribe(count => {
      this.paginator.length = count;
    });
  }

  select(subject: Subject) {
    this.dialogRef.close(subject);
  }


  //---------------------------------------------FILTER-----------------------------------------------------------------

  get f() {
    return this.filter.controls;
  }

  doFilter() {
    this.paginator.firstPage();
    this.loadData();
  }

  resetFilter () {
    this.f['parent'].disable();
    this.buildFilter();
    this.paginator.firstPage();
    this.loadData();
  }

  private buildFilter() {
    this.filter = this.formBuilder.group({
      bin: '',
      name: '',
      type: this.types[0],
      parent: this.parents[0],
      activityType: this.activityTypes[0]
    });
  }

  loadParentSubjects(type: SubjectType) {
    this.f['parent'].disable();
    this.f['parent'].setValue(this.parents[0]);
    this.f['activityType'].setValue(this.activityTypes[0]);
    if (type.name.indexOf('Другие ЮЛ') != -1) {
      this.f['activityType'].enable();
      return;
    }
    if (type.parent != null) {
      this.service.getByType(type.parent).subscribe(data => {
        if (data.length > 0) {
          this.parents = this.parents.concat(data);
          this.f['parent'].enable();
          this.f['parent'].setValue(this.parents[0]);
        }
      });
    }
  }
}
