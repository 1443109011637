import {Component, OnInit, Inject} from '@angular/core';
import {User} from "../../model/user";
import {UserService} from "../../service/user.service";
import {Router} from "@angular/router";
import {MatDialogRef, MAT_DIALOG_DATA} from "@angular/material";
import {AbonentService} from "../../service/abonent.service";
import {HttpParams} from "@angular/common/http";
import {IpRange} from "../../model/ip-range";
import {TranslateService} from "@ngx-translate/core";

@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.css']
})
export class UserDetailComponent implements OnInit {
  header: string;
  user: User;
  isLoading = false;
  private id = 0;
  abonentCount = 0;

  constructor(
    private service: UserService,
    private router: Router,
    public dialogRef: MatDialogRef<UserDetailComponent>,
    @Inject(MAT_DIALOG_DATA)
    private data: string,
    private abonentService: AbonentService,
    private translateService: TranslateService
  ) {
    this.router.events
      .subscribe(() => {
        dialogRef.close();
      });
  }

  ngOnInit() {
    this.setHeaderTranslation('BASIC.LOADING');
    this.user = new User();
    this.id = +this.data;
    this.loadData();
  }

  private loadData() {
    this.isLoading = true;
    this.service.get(this.id).subscribe(data => {
      this.user = data;
      this.setHeaderTranslation('GENERAL.OS_USER');
      this.isLoading = false;
      this.loadAbonentCount();
    });
  }

  private loadAbonentCount() {
    let params = new HttpParams()
      .set('createdBy', String(this.id));
    this.abonentService.getCount({params: params}).subscribe(count => {
      this.abonentCount = count;
    });
  }

  getDate(ipRange: IpRange) {
    return ipRange.eshdiConnectedOperator ? ipRange.eshdiConnectDate == null ? '' : ipRange.eshdiConnectDate
      : ipRange.eshdiDisconnectDate == null ? '' : ipRange.eshdiDisconnectDate;
  }

  showAbonents() {
    if (this.abonentCount == 0)
      return;
    this.router.navigate(['abonent/all'], { queryParams: { createdBy: this.id } });
  }

  private setHeaderTranslation(translationKey: string) {
    this.translateService.stream(translationKey).subscribe(translate => {
      this.header = translate;
    });
  }
}
