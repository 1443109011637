import { Injectable } from '@angular/core';
import {HttpService} from "./http.service";
import {Observable} from "rxjs";
import {Region} from "../model/region";

@Injectable({
  providedIn: 'root'
})
export class RegionService {

  constructor(private http: HttpService) {}

  public getAll(): Observable<Region[]> {
    return this.http.get('/region/all');
  }

  public getMainAll(): Observable<Region[]> {
    return this.http.get('/region/main/all');
  }

  public getParents(): Observable<Region[]> {
    return this.http.get('/region/parent/all');
  }
}
