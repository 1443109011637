import { Injectable } from '@angular/core';
import {IpRange} from "../model/ip-range";
import {Observable} from "rxjs";
import {HttpService} from "./http.service";

@Injectable({
  providedIn: 'root'
})
export class EshdiService {

  constructor(private http: HttpService) { }

  public loadIpRanges(options?: Object): Observable<IpRange[]> {
    return this.http.get('/eshdi', options);
  }

  public getCount(options?: Object): Observable<number> {
    return this.http.get('/eshdi/count', options);
  }

  public loadErrorIpRanges(options?: Object): Observable<IpRange[]> {
    return this.http.get('/eshdi/--error', options);
  }

  public loadErrorIpRangesCount(options?: Object): Observable<number> {
    return this.http.get('/eshdi/--error/count', options);
  }

  public _export(): Observable<any> {
    return this.http.get('/eshdi/export');
  }

  public errorExport(options?: Object): Observable<any> {
    return this.http.get('/eshdi/--error/export', options);
  }
}
