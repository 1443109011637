import {Component, OnInit, ViewChild} from '@angular/core';
import {HistoryService} from "../service/history.service";
import {MatTableDataSource, MatSort, MatPaginator} from "@angular/material";
import {History} from "../model/history";
import {HttpParams} from "@angular/common/http";

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})
export class HistoryComponent implements OnInit {
  displayedColumns: string[] = ['id', 'ipRange', 'bin', 'subject', 'registered', 'unregistered'];
  dataSource: MatTableDataSource<History>;
  filterString = '';

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;

  constructor(
    private service: HistoryService,
  ) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.loadData();
  }

    loadData() {
    let params = new HttpParams()
      .set('pageIndex', this.paginator.pageIndex == null ? '0' : String(this.paginator.pageIndex))
      .set('pageSize', this.paginator.pageSize == null ? '10' : String(this.paginator.pageSize))
      .set('filterString', this.filterString);

    this.loadCount(params);
    this.service.getAll({params: params}).subscribe(
      data => {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch(property) {
            case 'ipRange': return item.ipRange.value;
            case 'bin': return item.subject.bin;
            case 'subject': return item.subject.name;
            default: return item[property];
          }
        };
        this.dataSource.sort = this.sort;
      }
    );
  }

  loadCount(params: HttpParams) {
    this.service.getCount({params: params}).subscribe(count => {
      this.paginator.length = count;
    });
  }

  _export() {
    this.service._export().subscribe(data => {
      let blob = new Blob([data], {type: 'application/vnd.ms-excel'});
      let url = window.URL.createObjectURL(blob);
      let filename = 'История_регистрации.xls';
      if (navigator.msSaveOrOpenBlob) {
        navigator.msSaveBlob(blob, filename);
      } else {
        let a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
      window.URL.revokeObjectURL(url);
    });
  }

  doFilter(filterValue: string) {
    if (filterValue != null) {
      this.filterString = filterValue.trim().toLowerCase();
    }
    this.paginator.firstPage();
    this.loadData();
  }
}
