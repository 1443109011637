import {SubjectType} from "../../model/subject-type";
import {ActivityType} from "../../model/activity-type";
import {Subject} from "../../model/subject";
import {Component, OnDestroy, OnInit, ViewChild} from "@angular/core";
import {MatTableDataSource, MatPaginator, MatSort} from "@angular/material";
import {Event} from "../../model/event";
import {FormGroup, FormBuilder, Validators, FormControl} from "@angular/forms";
import {EventService} from "../../service/event.service";
import {Router} from "@angular/router";
import {HttpParams} from "@angular/common/http";
import {TranslateService} from "@ngx-translate/core";
import {Subscription} from "rxjs";
import {Operator} from "../../model/operator";
import {OperatorService} from "../../service/operator.service";
import {DatePipe} from "@angular/common";
import {UserService} from "../../service/user.service";

let emptyType = new SubjectType();
emptyType.id = 0;
emptyType.name = 'Все';
let emptyActivityType = new ActivityType();
emptyActivityType.id = 0;
emptyActivityType.name = 'Все';
let emptyParent = new Subject();
emptyParent.id = 0;
emptyParent.bin = '0';
emptyParent.name = 'Все';
let emptyOperator = new Operator();
emptyOperator.id = 0;
emptyOperator.asn = '0';
emptyOperator.name = 'Все';
// RegEx below matches the date in the format dd.mm.yyyy including leap years
const dateRegex = '^(?:(?:31(\\.)(?:0?[13578]|1[02]))\\1|(?:(?:29|30)(\\.)' +
  '(?:0?[13-9]|1[0-2])\\2))(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$|^(?:29(\\.)0?2\\3(?:(?:(?:1[6-9]|[2-9]\\d)?' +
  '(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\\d|2[0-8])(\\.)' +
  '(?:(?:0?[1-9])|(?:1[0-2]))\\4(?:(?:1[6-9]|[2-9]\\d)?\\d{2})$'

@Component({
  selector: 'app-event-list',
  templateUrl: './event-list.component.html',
  styleUrls: ['./event-list.component.css']
})
export class EventListComponent implements OnInit, OnDestroy {
  operators: Operator[] = [emptyOperator];
  displayedColumns: string[] = ['id', 'eventDate', 'event'];
  dataSource: MatTableDataSource<Event>;
  pageSizeArray = [10, 25, 50, 100];
  isLoading = true;
  id = 0;
  private translationSubscriptions: Subscription[] = [];

  @ViewChild(MatPaginator)
  paginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;
  startDate = new FormControl('', [
    Validators.required,
    Validators.pattern(dateRegex)
  ]);
  endDate = new FormControl('', [
    Validators.required,
    Validators.pattern(dateRegex)
  ]);
  filter: FormGroup;
  eventType = [
    {key: 'AUTH', value: ''},
    {key: 'CREATE', value: ''},
    {key: 'UPDATE', value: ''},
    {key: 'DELETE', value: ''}
  ];

  constructor(
    private eventService: EventService,
    private operatorService: OperatorService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private datePipe: DatePipe,
    private userService: UserService
  ) {
    this.dataSource = new MatTableDataSource([]);
    this.dataSource.paginator = this.paginator;
  }

  ngOnInit() {
    this.buildFilter();
    this.loadData();

    this.setEventTypeTranslations();
    emptyOperator.name = this.getTranslation('OTHER.ALL');

    this.translateService.onLangChange.subscribe(() => {
      this.setEventTypeTranslations();
      emptyOperator.name = this.getTranslation('OTHER.ALL');
    });
    this.userService.getCurrentUserInfo().subscribe(data => {
        this.id = data.id;
    });
  }

  loadData() {
    this.isLoading = true;

    const selectedStartDate: Date = this.f['startDate'].value;
    const formattedStartDate = this.datePipe.transform(selectedStartDate, 'yyyy-MM-dd HH:mm:ss');
    const selectedEndDate: Date = this.f['endDate'].value;
    const formattedEndDate = this.datePipe.transform(selectedEndDate, 'yyyy-MM-dd HH:mm:ss');

    let params = new HttpParams()
      .set('name', this.f['name'].value)
      .set('bin', this.f['bin'].value)
      .set('eventType', this.f['eventType'].value)
      .set('startDate', formattedStartDate)
      .set('endDate', formattedEndDate)
      .set('pageIndex', this.paginator.pageIndex == null ? '0' : String(this.paginator.pageIndex))
      .set('pageSize', this.paginator.pageSize == null ? '10' : String(this.paginator.pageSize));

    this.loadCount(params);
    this.eventService.getAll({params: params}).subscribe(
      data => {
        this.isLoading = false;
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sort = this.sort;
      }
    );
  }

  loadCount(params: HttpParams) {
    this.eventService.getCount({params: params}).subscribe(count => {
      this.paginator.length = count;
    });
  }

  generateEvent(event: Event) {
    let firstWord: string;
    let username: string;
    let action: string;

    if (event.user != null) {
      firstWord = this.getTranslation('GENERAL.USER');
      username = event.user.fullName == null || event.user.fullName.trim() == ""
        ? event.user.username : event.user.fullName;
    } else {
      firstWord = this.getTranslation('GENERAL.ORGANIZATION');
      username = event.organizationUser.username;
    }

    if (event.eventType.includes('AUTH'))
      action = this.getTranslation('EVENT_LOG.AUTHORIZE_SYSTEM');
    if (event.eventType.includes('CREATE'))
      action = this.getTranslation('EVENT_LOG.CREATE_ABONENT');
    if (event.eventType.includes('UPDATE'))
      action = this.getTranslation('EVENT_LOG.CHANGE_ABONENT');
    if (event.eventType.includes('DELETE'))
      action = this.getTranslation('EVENT_LOG.DELETE_ABONENT');

    return firstWord + ' ' + username + ' ' + action;
  }

  setEventTypeTranslations() {
    this.eventType[0].value = this.getTranslation('EVENT_LOG.AUTH');
    this.eventType[1].value = this.getTranslation('EVENT_LOG.CREATE');
    this.eventType[2].value = this.getTranslation('EVENT_LOG.UPDATE');
    this.eventType[3].value = this.getTranslation('EVENT_LOG.DELETE');
  }

  private getTranslation(translationKey: string) {
    let translation: string;
    const subscription = this.translateService.stream(translationKey).subscribe(translate => {
      translation = translate;
    });
    this.translationSubscriptions.push(subscription);
    return translation;
  }

  ngOnDestroy() {
    this.translationSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  //---------------------------------------------FILTER-----------------------------------------------------------------

  get f() {
    return this.filter.controls;
  }

  doFilter() {
    this.paginator.firstPage();
    this.loadData();
  }

  resetFilter() {
    this.buildFilter();
    this.paginator.firstPage();
    this.loadData();
  }

  private buildFilter() {
    this.filter = this.formBuilder.group({
      bin: '',
      name: '',
      eventType: '',
      startDate: '',
      endDate: ''
    });
  }
}
