import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {HttpService} from "./http.service";
import {TokenStorage} from "../security/token.storage";
import {NgxPermissionsService} from "ngx-permissions";
import {UserService} from "./user.service";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  constructor(
    private http: HttpService,
    private tokenStorage: TokenStorage,
    private userService: UserService,
    private router: Router,
    private permissionsService: NgxPermissionsService
  ) {}

  public auth(username: string, password: string): void {
    const data = {username: username.trim().toLowerCase(), password: password.trim()};
    this.http.post('/operator/login', data).subscribe(
      data => {
        this.tokenStorage.save(data.token);
        this.userService.updatePermissions();
        this.router.navigate(['']);
      }
    );
  }

  public out(): void {
    this.permissionsService.flushPermissions();
    this.tokenStorage.clear();
    this.router.navigate(['login']);
  }

}
