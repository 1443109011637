import {Injectable} from "@angular/core";


const TOKEN_KEY = 'AuthToken';

@Injectable()
export class TokenStorage {
  public clear() {
    localStorage.removeItem(TOKEN_KEY);
  }

  public save(token: string) {
    this.clear();
    localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string {
    return localStorage.getItem(TOKEN_KEY);
  }
}
