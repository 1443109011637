import {Role} from './role';
import {Subject} from './subject';

export class OrganizationUser {
  id: number;
  bin: string;
  name: string;
  username: string;
  password: string;
  role: Role;
  email: string;
  registered: string;
  subjectList: Subject[];
  madeChanges: boolean;
  phone: string;
  fullName: string;
}
