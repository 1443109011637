import { Injectable } from '@angular/core';
import {HttpService} from "./http.service";
import {Abonent} from "../model/abonent";
import {Observable} from "rxjs";
import {AbonentHistory} from "../model/abonent-history";
import {IpAddress} from '../model/ip-address';

@Injectable({
  providedIn: 'root'
})
export class AbonentService {

  constructor(private http: HttpService) { }

  public getAll(options?: Object): Observable<Abonent[]> {
    return this.http.get('/abonent/operator/all', options);
  }

  public getCount(options?: Object): Observable<number> {
    return this.http.get('/abonent/operator/all/count', options);
  }

  public edit(abonent: Abonent): Observable<any> {
    return this.http.post('/abonent', abonent);
  }

  public get(id: number): Observable<Abonent> {
    return this.http.get('/abonent/' + id);
  }

  public remove(id: number): Observable<any> {
    return this.http.post('/abonent/delete', id);
  }

  public importFile(formData: FormData): Observable<any> {
    return this.http.post('/abonent/--import', formData);
  }

  public getHistory(id: number): Observable<AbonentHistory[]> {
    return this.http.get('/abonent/' + id + '/history');
  }

  public getHistoryCount(id: number): Observable<number> {
    return this.http.get('/abonent/' + id + '/history/count');
  }
  public _export(options?: Object): Observable<any> {
    return this.http.get('/abonent/--export', options);
  }

  public getIps(options?: Object): Observable<IpAddress[]> {
    return this.http.get('/abonent/ips', options);
  }
}
