import {NativeDateAdapter} from "@angular/material/core";

export class CustomDateAdapter extends NativeDateAdapter {
  parse(value: any): Date | null {
    if ((typeof value === 'string') && (value.indexOf('.') > -1)) {
      const str = value.split('.');
      const year = Number(str[2]);
      const month = Number(str[1]) - 1;
      const date = Number(str[0]);
      return new Date(year, month, date);
    }
    return super.parse(value); // Fallback to the default parser
  }

  format(date: Date, displayFormat: Object): string {
    if (displayFormat == "input") {
      let day = date.getDate();
      let month = date.getMonth() + 1;
      let year = date.getFullYear();
      // Pad single-digit day and month with leading zero
      let formattedDay = day < 10 ? `0${day}` : day;
      let formattedMonth = month < 10 ? `0${month}` : month;
      return `${formattedDay}.${formattedMonth}.${year}`;
    } else {
      return date.toDateString();
    }
  }
}

