import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Abonent} from "../../model/abonent";
import {MatTableDataSource, MatPaginator, MatSort} from "@angular/material";
import {IpRange} from "../../model/ip-range";
import {AbonentService} from "../../service/abonent.service";
import {Router, ActivatedRoute} from "@angular/router";
import {User} from "../../model/user";
import {Subscription} from "rxjs";
import {TranslateService} from "@ngx-translate/core";
import {AbonentHistory} from "../../model/abonent-history";


@Component({
  selector: 'app-abonent-detail',
  templateUrl: './abonent-detail.component.html',
  styleUrls: ['./abonent-detail.component.css']
})
export class AbonentDetailComponent implements OnInit, OnDestroy {
  header: string;
  isLoading = false;
  abonent: Abonent;
  id = 0;
  isDeleted = false;
  private translationSubscriptions: Subscription[] = [];
  selectedIpRange: any = null;

  displayedColumns: string[] = ['created', 'updated', 'ipRangeSet', 'eshdiCo', 'eshdi', 'eshdiDate'];
  displayedHistoryColumns: string[] = ['actionDate', 'actionType'];
  pageSizeArray = [10, 25, 50, 100];
  ipRangeDataSource: MatTableDataSource<IpRange>;
  historyDataSource: MatTableDataSource<AbonentHistory>;

  @ViewChild('ipRangePaginator')
  ipRangePaginator: MatPaginator;
  @ViewChild('historyPaginator')
  historyPaginator: MatPaginator;
  @ViewChild(MatSort)
  sort: MatSort;


  constructor(
    private service: AbonentService,
    private router: Router,
    private route: ActivatedRoute,
    private translateService: TranslateService) {
    this.ipRangeDataSource = new MatTableDataSource([]);
    this.historyDataSource = new MatTableDataSource([]);
  }

  ngOnInit() {
    this.abonent = new Abonent();
    this.id = +this.route.snapshot.paramMap.get('id');
    this.setHeaderTranslation('OTHER.ABONENT_CARD');
    this.loadData();
    this.loadHistoryData();
  }

  private loadData() {
    this.isLoading = true;
    this.loadCount();
    this.service.get(this.id).subscribe(data => {
      this.abonent = data;
      this.isDeleted = !data.active && data.deleted != null;
      this.ipRangeDataSource = new MatTableDataSource(this.abonent.ipRangeSet);
      this.ipRangeDataSource.paginator = this.ipRangePaginator;
      this.ipRangeDataSource.sort = this.sort;
      this.isLoading = false;
    });
  }

  private loadHistoryData() {
    this.isLoading = true;
    this.loadHistoryCount();
    this.service.getHistory(this.id).subscribe(data => {
      this.historyDataSource = new MatTableDataSource(data);
      this.historyDataSource.paginator = this.historyPaginator;
      this.historyDataSource.sort = this.sort;
      this.isLoading = false;
    });
  }

  loadCount() {
    this.service.get(this.id).subscribe(data => {
      this.ipRangePaginator.length = data.ipRangeSet.length;
    });
  }

  loadHistoryCount() {
    this.service.getHistoryCount(this.id).subscribe(count => {
      this.historyPaginator.length = count;
    });
  }

  editAbonent() {
    if (this.abonent != null) {
      this.router.navigate(['abonent/' + this.abonent.id]);
    }
  }

  getOperatorUserName(user: User) {
    if (user == null)
      return;
    if (user.fullName != null && user.fullName != '')
      return user.fullName;
    else
      return user.username;
  }

  generateActionString(abonentHistory: AbonentHistory) {
    let text: string;

    let operatorStr = this.getTranslation('GENERAL.OPERATOR');
    let organizationStr = this.getTranslation('GENERAL.ORGANIZATION');
    let adminStr = this.getTranslation('GENERAL.ADMIN');

    if (abonentHistory.operatorUser != null) {
      text = operatorStr + " " + abonentHistory.operatorUser.username + " ";
    } else if (abonentHistory.organizationUser != null) {
      text = organizationStr + " " + abonentHistory.organizationUser.username + " ";
    } else {
      text = adminStr + " ";
    }

    const actionMappings = {
      "IP_ADD": () =>
        `${this.getTranslation('ABONENT_HISTORY.IP_ADD')}: ${abonentHistory.ipRangeValue}`,
      "IP_CHANGE": () =>
        `${this.getTranslation('ABONENT_HISTORY.IP_CHANGE')}: ${abonentHistory.oldIpRangeValue} -> ${abonentHistory.ipRangeValue}`,
      "IP_DELETE": () =>
        `${this.getTranslation('ABONENT_HISTORY.IP_DELETE')}: ${abonentHistory.oldIpRangeValue}`,
      "ESHDI_STATUS_CHANGE": () =>
        `${this.getTranslation('ABONENT_HISTORY.ESHDI_STATUS_CHANGE')}: ${abonentHistory.ipRangeValue} -> ${this.getEshdiStatus(abonentHistory)}`,
      "CONFIRM_STATUS_CHANGE": () =>
        `${this.getTranslation('ABONENT_HISTORY.CONFIRM_STATUS_CHANGE')}: ${abonentHistory.ipRangeValue} -> ${this.getConfirmStatus(abonentHistory)}`,
      "SUBJECT_CHANGE": () =>
        `${this.getTranslation('ABONENT_HISTORY.SUBJECT_CHANGE')}: ${abonentHistory.oldSubject.name} -> ${abonentHistory.subject.name}`,
      "REGION_CHANGE": () =>
        `${this.getTranslation('ABONENT_HISTORY.REGION_CHANGE')}: ${abonentHistory.oldRegion.name} -> ${abonentHistory.region.name}`,
    };

    if (abonentHistory.actionType in actionMappings) {
      text += actionMappings[abonentHistory.actionType]();
    }

    return text;
  }

  private getEshdiStatus(abonentHistory: AbonentHistory): string {
    return abonentHistory.isEshdiConnectedOperator
      ? this.getTranslation('ABONENT_HISTORY.CONNECTED')
      : this.getTranslation('ABONENT_HISTORY.NOT_CONNECTED');
  }

  private getConfirmStatus(abonentHistory: AbonentHistory): string {
    return abonentHistory.isConfirmed
      ? this.getTranslation('ABONENT_HISTORY.CONFIRMED')
      : this.getTranslation('ABONENT_HISTORY.REJECTED');
  }

  getDate(ipRange: IpRange) {
    return ipRange.eshdiConnectedOperator ? ipRange.eshdiConnectDate == null ? '' : ipRange.eshdiConnectDate
      : ipRange.eshdiDisconnectDate == null ? '' : ipRange.eshdiDisconnectDate;
  }

  private setHeaderTranslation(translationKey: string) {
    const subscription = this.translateService.stream(translationKey).subscribe(translate => {
      this.header = translate;
    });
    this.translationSubscriptions.push(subscription);
  }

  private getTranslation(translationKey: string) {
    let translation: string;
    const subscription = this.translateService.stream(translationKey).subscribe(translate => {
      translation = translate;
    });
    this.translationSubscriptions.push(subscription);
    return translation;
  }

  ngOnDestroy() {
    this.translationSubscriptions.forEach(subscription => {
      if (subscription) {
        subscription.unsubscribe();
      }
    });
  }

  showIpAddresses(row: any): void {
    // Если `row` содержит данные об IP-адресах, вы можете привязать их к `selectedIpRange`
    this.selectedIpRange = row;
  }
}
