import {Subject} from "./subject";
import {Operator} from "./operator";
import {IpRange} from "./ip-range";
import {Region} from "./region";
import {User} from "./user";

export class Abonent {
  id: number;
  created: string;
  updated: string;
  deleted: string;
  createdBy: User;
  deletedBy: User;
  subject: Subject;
  operator: Operator;
  ipRangeSet: IpRange[];
  region: Region;
  active: boolean;
}
