import { Component, OnInit } from '@angular/core';
import {UserService} from "../service/user.service";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {ErrorHandler} from "../service/error-handler.service";
import {CustomErrorStateMatcher} from "../util/custom-error-state-matcher";
import {NotifierService} from "angular-notifier";
import {passwordMatchValidator} from "../util/password-match-validator";
import {User} from "../model/user";
import {TranslateService} from "@ngx-translate/core";
import {NgxPermissionsService} from "ngx-permissions";
import {OrganizationUser} from "../model/OrganizationUser";
import {MatDialog} from "@angular/material/dialog";
import {ProfileOrganizationEditComponent} from "./profile-organization-edit/profile-organization-edit.component";

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
  data: User = new User();
  dataOrganization: OrganizationUser = new OrganizationUser();
  passwordForm: FormGroup;
  email: FormControl;
  changePassword: boolean;
  changeEmail: boolean;
  errorMatcher = new CustomErrorStateMatcher;

  constructor(
    private service: UserService,
    private formBuilder: FormBuilder,
    private errorHandler: ErrorHandler,
    private notifierService: NotifierService,
    private permissionsService: NgxPermissionsService,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) { }

  ngOnInit() {
    this.changePassword = false;
    this.changeEmail = false;
    this.loadData();

    this.email = this.formBuilder.control('', [
      Validators.email, Validators.required
    ]);
    this.passwordForm = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(6),
        Validators.pattern('^[a-zA-Z0-9]+$')
      ]],
      passwordConfirm: ['', [
        Validators.required
      ]]
    }, {validator: passwordMatchValidator});
  }

  loadData() {
    // this.service.getCurrentUserInfo().subscribe(
    //   data => {
    //     this.data = data;
    //     this.email.setValue(this.data.email);
    //   }
    // );
    if (this.permissionsService.getPermission('ORGANIZATION')) {
      this.service.getCurrentOrganizationUserInfo().subscribe(data => {
        this.dataOrganization = data;
        this.email.setValue(this.dataOrganization.email);
      });
    } else {
      this.service.getCurrentUserInfo().subscribe(data => {
          this.data = data;
          this.email.setValue(this.data.email);
        }
      );
    }
  }

  submitEmail() {
    if (this.email.invalid)
      return;

    this.service.changeCurrentUserEmail(this.email.value).subscribe(
      () => {
        let message: string = this.translateService.instant('BASIC.DATA_SAVED');
        this.notifierService.notify('success', message);
        this.ngOnInit();
      }
    );
  }

  submitPassword() {
    if (this.passwordForm.invalid)
      return;

    this.service.changeCurrentUserPassword(this.passwordForm.controls['password'].value).subscribe(
      () => {
        let message: string = this.translateService.instant('BASIC.DATA_SAVED');
        this.notifierService.notify('success', message);
        this.ngOnInit();
      }
    );
  }

  get f() {
    return this.passwordForm.controls;
  }

  cancelPassword() {
    this.changePassword = false;
    this.passwordForm.reset();
  }
  cancelEmail() {
    this.changeEmail = false;
    this.email.setValue(this.data.email);
  }

  editProfile(organization: OrganizationUser) {
    const dialogRef = this.dialog.open(ProfileOrganizationEditComponent, {
      data: organization
    });
    // dialogRef.afterClosed().subscribe(data => {
    //   if (data != null) {
    //     this.f['bin'].setValue(data.bin);
    //     this.f['name'].setValue(data.name);
    //     this.f['subjectList'].setValue(data.subjectList);
    //   }
    // });
  }
}
