import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, Router} from '@angular/router';
import {AbonentService} from '../service/abonent.service';
import {AuthGuard} from './auth.guard';


@Injectable({
  providedIn: 'root'
})

export class AbonentEditGuard implements CanActivate {

  constructor(
    private abonentService: AbonentService,
    private authGuard: AuthGuard,
    private router: Router
  ) {
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {

    return new Promise((resolve, reject) => {
      if (!this.authGuard.canActivate(route)) {
        return resolve(false);
      }
      this.abonentService.get(route.params.id).subscribe(abonent => {
        if (abonent == null) {
          this.router.navigate(['/abonent/all']);
          return resolve(false);
        }
        return resolve(true);
      });
    });
  }
}
