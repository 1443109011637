import { Component, OnInit } from '@angular/core';
import {Router} from "@angular/router";
import {UserService} from "../service/user.service";
import {AuthService} from "../service/auth.service";
import {TranslateService} from "@ngx-translate/core";
import {NgxPermissionsService} from "ngx-permissions";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {
  username = '';
  operator = '';

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private router: Router,
    private permissionsService: NgxPermissionsService,
    private translateService: TranslateService) {}

  ngOnInit() {
    if (this.permissionsService.getPermission('ORGANIZATION')) {
      this.userService.getCurrentOrganizationUserInfo().subscribe(data => {
        this.operator = data.name;
        this.username = data.username;
      });
    } else {
      this.userService.getCurrentUserInfo().subscribe(data => {
          this.operator = data.operator.name;
          this.username = data.username;
        }
      );
    }
  }

  public profile() {
    this.router.navigate(['profile']);
  }

  public out() {
    let message: string = this.translateService.instant('OTHER.CONFIRM_EXIT');
    if (confirm(message))
      this.authService.out();
  }
}
