import { Injectable } from '@angular/core';
import {Observable} from "rxjs";
import {HttpService} from "./http.service";
import {History} from "../model/history";

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  constructor(private http: HttpService) { }

  public getAll(options?: Object): Observable<History[]> {
    return this.http.get('/history', options);
  }

  public getCount(options?: Object): Observable<number> {
    return this.http.get('/history/count', options);
  }

  public _export(): Observable<any> {
    return this.http.get('/history/export');
  }
}
