import {Component, OnInit, Input} from '@angular/core';
import {User} from "../../model/user";
import {MatDialog} from "@angular/material";
import {UserDetailComponent} from "../user-detail/user-detail.component";

@Component({
  selector: 'user-link',
  templateUrl: './user-link.component.html',
  styleUrls: ['./user-link.component.css']
})
export class UserLinkComponent implements OnInit {
  @Input()
  user: User;

  constructor(
    private dialog: MatDialog
  ) { }

  ngOnInit() {
  }

  showDetail() {
    if (this.user.id == null)
      return;
    this.dialog.open(UserDetailComponent, {
      data: String(this.user.id)
    });
  }
}
